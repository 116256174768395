$transition-speed: 0.4s;

header#header {
  &.transition-out {
    .main-nav {
      .title-wrap {
        opacity: 0;
      }
    }
  }
  .main-nav {
    .title-wrap {
      .state-initialised &,
      .state-loading & {
        transition: $transition-speed opacity;
        will-change: opacity;
      }
    }
  }
}

.page-wrap {
  .intro-wrap,
  .quote-wrap,
  .tab-wrap,
  .carousel-wrap {
    .state-initialised &,
    .state-loading & {
      transition: $transition-speed opacity;
      will-change: opacity;
    }
  }
  .intro-wrap,
  .quote-wrap,
  .tab-wrap {
    .transition-in &,
    .transition-out & {
      opacity: 0;
    }
    .fos-home.transition-out & {
      opacity: 1;
    }
  }
  .carousel-wrap {
    .state-home-transition-out &,
    .transition-out-gallery & {
      opacity: 0;
    }
  }
  .intro-wrap {
    .fos-home.transition-in & {
      transition: none;
      opacity: 1;
    }
  }
}

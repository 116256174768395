$base-path: './';

@mixin webfont($name, $filename, $weight: 400, $style: normal, $woff2: false) {
  @font-face {
    font-family: "#{$name}";
    src: url($base-path + $filename + '.eot?#iefix') format('embedded-opentype'), // IE6-IE8
         url($base-path + $filename + '.woff2'), // advanced browsers
         url($base-path + $filename + '.woff') format('woff'); // other proper browsers
    font-weight: $weight;
    font-style: $style;
  }
}

@font-face {
  font-family: "Favorit";
  src: url('./FavoritTrial-Light.otf') format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url('./FavoritTrial-LightIta.otf') format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Favorit";
  src: url('./FavoritTrial-Regular.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url('./FavoritTrial-RegularIta.otf') format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Favorit";
  src: url('./FavoritTrial-Medium.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url('./FavoritTrial-MediumIta.otf') format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Favorit";
  src: url('./FavoritTrial-Bold.otf') format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url('./FavoritTrial-BoldIta.otf') format("opentype");
  font-weight: 700;
  font-style: italic;
}

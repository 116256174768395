header#header {
  @include grid-container;

  @include mobile-only {
    body.home &,
    .state-menu-visible & {
      background: $white;
    }
  }
  h1 {
    @include favorit(38px);
    font-size: 2.159vw;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: $green;
  }
  .main-nav {
    .about-links,
    .category-links {
      & > a {
        @include favorit(18px);
        font-size: 1.0227272727vw;
        letter-spacing: -0.5px;
        font-weight: 500;
        line-height: 1.1111111111;
        color: $light-grey;

        @include mobile-only {
          @include favorit(18px);
        }

        &.current {
          color: $green;
        }
        @include desktop-only {
          &:hover {
            color: $green;
          }
        }
        body.home & {
          color: $green;

          @include desktop-only {
            &:hover {
              color: $light-grey;
            }
          }
        }
      }
    }
    .category-links {
      background: $white;

      @include mobile-only {
        background: none;
      }
    }
  }
  .mobile-intro-wrap {
    .text {
      @include favorit(24px);
      font-weight: 500;
      color: $green;
    }
  }
  @include desktop-only {
    position: relative;

    .hamburger {
      display: none;
    }
    .main-nav {
      position: relative;
      @include ib-grid(44);
      @include prefix(1);
      @include suffix(1);
      padding-top: percentage(4 / $total-cols);

      .about-links,
      .category-links,
      .title-wrap {
        position: absolute;
        top: percentage(1/4);

        body.home.state-initialised & {
          transition: transform ease-in-out 1.25s;
        }
        body.home & {
          transform: translateY(-100%);

          .state-home-loaded & {
            transform: translateY(0);
          }
        }
      }

      .category-links {
        position: fixed;
        @include ib-grid(21);
        width: calc((100% / #{$total-cols}) * 21 + 1px);
        width: calc((100% / #{$total-cols}) * 21 + 1px);
        padding: percentage(1 / $total-cols) 0;
        padding-left: percentage(1 / $total-cols);
        top: 0;
        left: 0;
        z-index: 10;

        body.home & {
          width: calc((100% / #{$total-cols}) * 21);
        }
        & > a {
          @include ib-grid(3, 21);
          @include suffix(1, 21);
          vertical-align: bottom;
        }
      }
      .title-wrap {
        @include ib-grid(13, 44);
        @include suffix(1, 44);
        left: percentage(22/44);

        h1 {
          margin: 0;

          p {
            margin: 0;
          }
        }
      }
      .about-links {
        @include ib-grid(8, 44);
        top: 0;
        padding: percentage(1 / $total-cols) 0;
        left: percentage(36/44);

        & > a {
          @include ib-grid(2, 8);
          @include suffix(1, 12);
        }
      }
    }
    .logo-wrap {
      position: absolute;
      @include ib-grid(8);
      top: 100%;
      left: percentage(36 / $total-cols);
      z-index: 10;

      // .state-overlay-logo-visible & {
      //   position: fixed;
      //   z-index: 18;
      //   top: 0;
      //   padding-top: percentage(3 / $total-cols);
      //   right: percentage(3 / $total-cols);
      // }
    }
  }
  @include mobile-only {
    position: fixed;
    width: 100%;
    padding-top: 70px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 11;

    body.home &,
    .state-menu-visible & {
      height: 100%;
    }
    body.home & {
      height: 100%;
      padding-top: percentage(2 / $total-cols);
    }
    .hamburger {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 11;

      body.home & {
        display: none;
      }
    }
    .logo-wrap {
      @include ib-grid(12);
      @include prefix(2);
      @include suffix(32);
      margin-bottom: 45px;
      display: none;

      body.home &,
      .state-menu-visible & {
        display: block;
      }
    }
    .mobile-intro-wrap {
      @include ib-grid(42);
      @include prefix(2);
      @include suffix(2);
      margin-bottom: 70px;
      display: none;

      body.home & {
        display: block;
      }
    }
    .main-nav {
      position: relative;
      display: none;

      body.home &,
      .state-menu-visible & {
        display: block;
      }
      .title-wrap {
        display: none;
      }
      .category-links,
      .about-links {
        @include ib-grid(18);
        @include prefix(26);
        @include suffix(2);
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: block;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .shape-wrap {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;

        .image-wrap {
          display: none;

          &.shape-2 {
            display: block;
          }
        }
      }
    }
  }
}

#transition-wrap {
  .image {
    background: $white;
  }
  @include desktop-only {
    display: none;
  }
  @include mobile-only {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 20;
    transition: clip-path 1.25s ease-in;
    clip-path: polygon(0 0, 100% 0%, 100% 0%, 0% 0%);
    display: none;

    .state-mobile-transition & {
      display: block;
    }
    .state-mobile-transition-in & {
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
    .state-mobile-transition-out & {
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    }

    .image {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

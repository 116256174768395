.page-wrap {
  @include mobile-only {
    @include favorit(13px);
  }
  @include desktop-only {
    position: relative;
    @include ib-grid(25);
    @include prefix(21);
    padding-top: percentage((4 * 9) / $total-cols);
  }
  @include mobile-only {
    @include ib-grid(42);
    @include prefix(2);
    @include suffix(2);
    padding-top: 70px;

    .state-menu-visible & {
      visibility: hidden;
      overflow: hidden;
    }
  }
  .text-wrap {
    h4 {
      @include favorit(24px);
      font-weight: 500;
      font-size: 1.0227272727vw;
      color: $green;
      letter-spacing: -0.5px;

      @include mobile-only {
        font-size: 16px;
      }
    }
    .title-wrap {
      h1 {
        @include favorit(24px);
        font-weight: 500;
        color: $green;
      }
    }
    .intro-wrap {
      article.fos-contact & {
        font-size: 1.0227272727vw;
        font-weight: 500;
        line-height: 1.2777777778;
        letter-spacing: -0.5px;
        color: $green;

        @include mobile-only {
          @include favorit(20px);
          font-weight: 500;
          letter-spacing: -0.5px;
        }
      }
    }
    .quote-wrap {
      @include favorit(24px);
      font-size: 1.3636363636vw;
      letter-spacing: -0.5px;
      color: $light-grey;

      @include mobile-only {
        @include favorit(20px);
        font-weight: 500;
        color: $green;
      }
    }
    @include desktop-only {
      position: absolute;
      @include ib-grid(22, 25);
      @include prefix(2, 25);
      @include suffix(1, 25);
      top: 0;
      left: 0;
      padding-top: percentage(17/25);

      .text {
        & > :last-child {
          margin-bottom: 0;
        }
      }
      .intro-wrap {
        position: absolute;
        @include ib-grid(11, 22);
        @include suffix(3, 22);
        top: 0;
        left: 0;
      }
      .quote-wrap {
        position: absolute;
        @include ib-grid(8, 22);
        left: percentage(14/22);
        bottom: percentage(3/17);

        .text {
          & > :last-child {
            margin: 0;
          }
        }
      }
    }
    @include mobile-only {
      .text {
        & > :last-child {
          margin-bottom: 0;
        }
      }
      .title-wrap {
        @include ib-grid(32, 42);
        @include suffix(1, 42);
        min-height: 22.2666666667vw;
        padding-bottom: 10px;

        h1 {
          margin: 0;

          p {
            margin: 0;
          }
        }
      }
      .logo-wrap {
        position: fixed;
        @include ib-grid(9, 46);
        left: percentage(35/46);
        margin-bottom: 10px;
        z-index: 5;
      }
      .intro-wrap {
        @include ib-grid(31, 42);
        margin-bottom: 70px;

        .text {
          & > :first-child {
            @include ib-grid(28, 31);
          }
        }
      }
      .quote-wrap {
        @include ib-grid(31, 42);
        margin-bottom: 70px;
      }
    }
  }
  .carousel-wrap {
    &:hover {
      .carousel-item {
        .image-wrap {
          img {
            @include desktop-only {
              @include grayscale(0);
            }
          }
        }
      }
    }
    .title {
      font-weight: 500;
    }
    .carousel-item {
      .video-player-wrap .text:nth-child(2),
      .project-title {
        @include mobile-only {
          @include favorit($body-font-size);
          font-weight: 500;
          color: $green;
        }
      }
      .video-player-wrap,
      .image-wrap {
        img {
          @include desktop-only {
            transition: filter 0.25s;
            @include grayscale(1);
          }
        }
        .text {
          @include mobile-only {
          }
        }
      }
      .video-player-wrap {
        @include desktop-only {
          .videoplayer-inner {
            padding-bottom: 0;

            .video-image {
              display: none;
            }
          }
          .text {
            display: none;

            &:last-child {
              display: block;
            }
          }
        }
        @include mobile-only {
          .videoplayer-inner {
            padding-bottom: 0;

            .gallery-image {
              display: none;
            }
          }
          .text {
            &:nth-child(2) {
              margin-bottom: 20px;
            }
            &:nth-child(3) {
              display: none;
            }
          }
        }
      }
    }
    @include desktop-only {
      position: absolute;
      @include ib-grid(25, 25);
      top: percentage(20/36);
      left: 0;

      .carousel {
        .carousel-item {
          &.project-secondary {
            .project-title,
            .text {
              display: none;
            }
          }
        }
      }
      .carousel-tabs {
        position: absolute;
        width: 100%;
        bottom: 100%;
        left: 0;
        padding-bottom: percentage(2/25);

        @include carousel-tabs(25);
      }
    }
    @include mobile-only {
      margin-bottom: 100px;

      .title {
        margin-bottom: 25px;
      }
      .carousel {
        .carousel-item {
          display: block;
          margin-bottom: 20px;

          &.project-last {
            margin-bottom: 80px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .image,
          .project-title {
            margin-bottom: 20px;
          }
          &.project-secondary {
            .project-title,
            .text {
              display: none;
            }
          }
        }
      }
      .carousel-tabs {
        display: none;
      }
    }
  }
}

article.fos-home,
article.fos-four-zero-four {
  .page-wrap {
    .text-wrap {
      .intro-wrap {
        .text {
          font-size: 2.2727272727vw;
          letter-spacing: -0.5px;
          font-weight: 500;
          line-height: 1;
          color: $white;

          h1 {
            font-size: 5.7954545455vw;
            margin: 0;
          }
        }
        @include desktop-only {
          @include ib-grid(10, 22);
          top: percentage(5/17);
          left: percentage(-1/22);
          z-index: 10;
        }
      }
    }
    @include mobile-only {
      display: none;
    }
  }
}

$overlay-transition-speed: 1.75s;
$overlay-transition-type: ease-in;

#overlay {
  @include grid-container;

  @include desktop-only {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: none;
    z-index: 15;
    overflow: hidden;
    overflow-y: auto;

    .state-overlay-visible & {
      display: block;
    }
    .overlay-inner {
      position: relative;
      padding-top: percentage(40 / $total-cols);
    }
  }
  @include mobile-only {
    display: none;
  }
  .video-player {
    @include desktop-only {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 17;
      visibility: hidden;

      body.state-initialised & {
        transition: clip $overlay-transition-speed $overlay-transition-type;
      }
      .state-overlay-transition &,
      .state-overlay-transition-in &,
      .state-overlay-ready & {
        visibility: inherit;
      }
      // .state-overlay-transition-video-in & {
      //   transform: translateX(-100%);
      // }
      .gallery-image {
        display: none;
      }
    }
  }
  .text-wrap {
    color: $white;
    opacity: 0;
    transition: opacity 0.4s;
    will-change: opacity;

    .text {
      @include favorit(20px);
      font-weight: 500;
      font-size: 1.1363636364vw;
      line-height: 1.2777777778;
      letter-spacing: -0.5px;
    }

    .state-overlay-ready & {
      opacity: 1;
    }
    @include desktop-only {
      position: absolute;
      @include ib-grid(44);
      @include prefix(1);
      @include suffix(1);
      left: 0;
      top: calc((100vw * 0.5625) + 30px + 40px);
      z-index: 17;

      .text {
        @include ib-grid(7, 44);
        @include suffix(1, 44);

        p {
          margin: 0;
        }
      }
    }
  }
  .bkg {
    background: $green;

    @include desktop-only {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 100%;
      transform: translateX(0%);
      z-index: 16;

      body.state-initialised & {
        transition: transform $overlay-transition-speed $overlay-transition-type;
      }
      .state-overlay-ready &,
      .state-overlay-transition-in & {
        transform: translateX(-100%);
      }
    }
  }
  .overlay-close {
    @include favorit(18px);
    font-size: 1.0227272727vw;
    font-weight: 500;
    color: $green;
    cursor: pointer;

    &:hover {
      color: $light-grey;
    }
    @include desktop-only {
      position: fixed;
      top: percentage(1 / $total-cols);
      left: percentage(0.5 / $total-cols);
      z-index: 18;
      display: none;

      .state-overlay-ready & {
        display: block;
      }
    }
  }
}

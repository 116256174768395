@mixin lock-screen {
  width: 100% !important;
  height: calc(100vh - 1px) !important;
  overflow: hidden !important;
}

html {
  overflow-y: scroll;

  &.state-loading {
    @include lock-screen;
    overflow-y: visible !important;
  }
  &.state-overlay-visible {
    @include lock-screen;
    padding-right: 15px;
  }
  @include mobile-only {
    &.state-menu-visible {
      @include lock-screen;
    }
    &.state-mobile-transition {
      @include lock-screen;
    }
  }
}
body {
  background: $white;
  color: $black;
  visibility: hidden;

  &.state-initialised {
    visibility: inherit;
  }
}

.content-wrap {
  position: relative;

  @include desktop-only {
    min-height: 100vh;
  }
  & > #content {
    > article {
      @include grid-container;
    }
  }
}

.background-wrap {
  @include grid-container;
  pointer-events: none;

  @include desktop-only {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    overflow: hidden;

    canvas {
      width: 100%;
      height: 100%;
    }
  }
  @include mobile-only {
    display: none;
  }
}

.no-desktop {
  @include desktop-only {
    display: none !important;
  }
}

.no-mobile {
  @include mobile-only {
    display: none !important;
  }
}

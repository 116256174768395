body,
table,
input,
textarea,
select {
  @include body-type;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;

  &.mce-content-body {
    font-size: $body-font-size;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

p {
  margin: 0 0 1em;

  @include mobile-only {
    margin: 0 0 0.7em;
  }
  > a {
    @include stealth-link;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
h4 {
  margin-top: 0;
}

.text {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      &:before {
        display: inline-block;
        content: "-";
        margin-bottom: 5px;
      }
    }
  }
}

.image {
  position: relative;

  &.lazy-load {
    &.loaded {
      img {
        display: block;
      }
    }
    img {
      display: none;
    }
  }
  img,
  .video-player {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .image-height-setter {
    display: block;
  }
  .video-player {
    z-index: 2;
    height: 100%;
    pointer-events: none;
    .video-wrap {
      height: 100%;
      video {
        // assume user will upload video with right dimensions
        min-height: 100%;
        min-width: 100%;
        visibility: visible;
        opacity: 0;
        transition: none;
      }
    }
    &.videoplayer-playing,
    &.videoplayer-paused {
      .video-wrap video {
        opacity: 1;
        transition: 0s opacity ease 0.1s;
      }
    }
  }
}

// .mobile-carousel only becomes a carousel on mobile screen sizes
.carousel,
.mobile-carousel {
  .caption {
    padding: 12px 0 0;
    @include favorit(14px);

    p {
      margin: 0;
    }
  }
  .flickity-page-dots {
    padding: 0 10px;
    text-align: center;
    display: none;

    .dot {
      width: 12px;
      height: 12px;
      opacity: 1;
      margin: 0 4px;
      background: $black;
      border: 1px solid $black;
      color: $black;

      &.is-selected,
      .no-touchevents &:hover {
        background: $light-grey;
        border-color: $light-grey;
      }
      @include mobile-only {
        width: 8px;
        height: 8px;
      }
    }
    @include mobile-only {
      position: absolute;
      display: block;
      bottom: 20px;
    }
  }
  .flickity-prev-next-button {
    background: none !important;
    top: calc(100% + 25px);
    @include favorit(20px);

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
    &.previous:after {
      content: "â";
    }
    &.next:after {
      content: "â";
    }
    svg {
      display: none;
    }
  }
  &.flickity-enabled {
    img {
      // prevent it capturing drags
      pointer-events: none;
    }
  }
}

@mixin carousel-tabs($parent-cols: $total-cols) {
  .tab-wrap {
    .tab {
      @include ib-grid(4, $parent-cols);
      @include prefix(0.5, $parent-cols);
      color: $green;
      cursor: pointer;

      &:hover,
      &.is-selected {
        .caption-text {
          opacity: 0.275;
        }
      }
      .number {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

@mixin carousel-enabled {
  &.flickity-enabled {
    &.is-fullscreen {
      padding-bottom: 0;

      .carousel-item {
        height: 100%;
      }
      .flickity-fullscreen-button {
        display: none;
      }
    }
    .carousel-item {
      display: block;
    }
    .flickity-fullscreen-button {
      display: none;
    }
  }
  .carousel-item {
    width: 100%;
    display: none;

    &:first-child {
      display: block;
    }
  }
}

.carousel {
  @include carousel-enabled;
}
.mobile-carousel {
  @include mobile-only {
    @include carousel-enabled;
  }
}

.carousel-wrap {
  .carousel-item {
    .video-player-wrap,
    .image-wrap {
      .text {
        @include favorit(24px);
        font-weight: 500;
        font-size: 1.9886363636vw;
        line-height: 1.2857142857;
        color: $white;

        @include mobile-only {
          @include favorit(20px);
          color: $light-grey;
        }

        p {
          margin-bottom: 0;
        }
      }
      .image,
      .poster {
        cursor: move;
        cursor: -webkit-grab;
        cursor: grab;
      }
    }
    @include desktop-only {
      .video-player-wrap,
      .image-wrap {
        position: relative;

        .text {
          position: absolute;
          left: percentage(1/25);
          bottom: percentage(2/16);
        }
      }
    }
  }
}
